import * as Sentry from '@sentry/nextjs'

import axios from 'axios'

import {
  AllCitiesParams,
  AllCitiesResponse,
  AllParkingsParams,
  AllParkingsResponse,
  Categories,
  CategoriesResponse,
  ParkingsByCityParams,
  ParkingsByCityResponse,
  ParkingsBySlugParams,
  ParkingsBySlugResponse,
  ParkingsPlanParams,
  ParkingsPlanResponse
} from '@/service'

import { ApiPaths } from './apiPaths'

export const fetchParkings = async ({
  params
}: {
  params?: AllParkingsParams
}) => {
  try {
    const { data } = await axios.get<AllParkingsResponse>(
      ApiPaths.API_V1_PARKINGS,
      { params }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchParkingBySlug = async ({
  slug,
  params
}: {
  slug: string
  params?: ParkingsBySlugParams
}) => {
  try {
    const { data } = await axios.get<ParkingsBySlugResponse>(
      `${ApiPaths.API_V1_PARKINGS}/${slug}`,
      { params }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchParkingByPlan = async ({
  slug,
  params
}: {
  slug: string
  params?: ParkingsPlanParams
}) => {
  try {
    const { data } = await axios.get<ParkingsPlanResponse>(
      `/api/v2/parkings/${slug}/plans`,
      {
        params
      }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchAllCities = async ({
  params
}: {
  params?: AllCitiesParams
}) => {
  try {
    const { data } = await axios.get<AllCitiesResponse>(
      ApiPaths.API_V1_CITIES,
      {
        params
      }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchByCity = async (
  city: string,
  params: ParkingsByCityParams
) => {
  try {
    const { data } = await axios.get<ParkingsByCityResponse>(
      `${ApiPaths.API_V1_CITIES}/${city}/parkings`,
      {
        params
      }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchCategories = async (params: Categories) => {
  try {
    const { data } = await axios.get<CategoriesResponse>(
      `${ApiPaths.API_V1_CATEGORIES}`,
      {
        params
      }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}
